/* eslint-disable react/prop-types */
// src/templates/pages/ComplianceShield.js

import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import React from 'react'
import Layout from '../../components/Layout'
import StatsColumn from '../../components/StatsColumn.js'

function ComplianceShield({ data }) {
  const pageData = data.wpPage.template.complianceShieldpage
  const heroSection = pageData.hero
  const stats = heroSection.statistics || []
  const content = pageData.content || []

  return (
    <Layout>
      <Seo post={data.wpPage} />

      {/* Page hero */}
      <section
        className='min-h-[360px] bg-cover bg-center bg-no-repeat flex items-center'
        style={{
          backgroundImage: `url(${heroSection.backgroundImage.sourceUrl})`,
          objectFit: 'fill',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="py-responsive-xl-36 text-left text-white">
          <h1 className="text-2xl font-light">{heroSection.title}</h1>
          <p className="mt-responsive-xl-16 text-md leading-5 md:leading-6 font-regular md:max-w-[600px]">
            {heroSection.description}
          </p>
          <div className="mt-responsive-xl-0 md:mt-responsive-xl-16 flex flex-col md:flex-row">
            {stats.map((stat, index) => {
              return (
                <StatsColumn
                  key={`${stat.description}--${index}`}
                  label={stat.description}
                  stat={stat.stat}
                  isLastItem={stats.length === index + 1}
                  isFirstItem={index === 0}
                  symbol={stat.symbol}
                />
              )
            })}
          </div>
          </div>
        </div>
      </section>

      {/* Content Row */}
      <section className="!mt-responsive-xl-48 container flex flex-col items-stretch md:flex-row gap-responsive-xl-40">
        {content.map((c, index) => {
          return (
            <div key={`${c.title}--${index}`} className='flex-1 py-responsive-xl-12 border-y-4 border-boynton-400'>
             <h2 className='text-lg font-bold text-left leading-7'>
                {c.title}
             </h2>
             <p className='mt-responsive-xl-16 text-sm leading-5 font-regular text-left'>{c.description}</p>
            </div>
          )
        })}
      </section>
    </Layout>
  )
}

export default ComplianceShield

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      template {
        templateName
        ... on WpTemplate_ComplianceShield {
          templateName
          complianceShieldpage {
            hero {
              title
              description
              backgroundImage {
                sourceUrl
              }
              statistics {
                stat
                description
                symbol
              }
            }
            content {
              title
              description
            }
          }
        }
      }
    }
  }
`
