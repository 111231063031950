import React from 'react'

export default function StatsColumn({
  label,
  stat,
  symbol,
  isFirstItem,
  isLastItem,
}) {
  return (
    <div className="flex">
      <div
        className={`!md:py-responsive-xl-48 w-max border-white py-responsive-xl-8 text-left md:w-full ${
          !isLastItem ? 'border-b-2 md:border-r-2' : ''
        } ${
          isFirstItem ? 'md:pr-responsive-xl-32' : 'md:px-responsive-xl-32'
        } md:border-b-0 md:py-px`}
      >
        {/* <p className="headline-sm content text-[4rem] font-light" /> */}
        <p className="text-[2rem] font-light md:text-[3rem]">{stat + symbol}</p>
        <p className="max-w-[260px] text-md font-regular md:text-lg">{label}</p>
      </div>
    </div>
  )
}
